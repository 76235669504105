import React, { Fragment } from 'react';
import styles from './styles.module.scss';
import { Grid, Row, Divider, Col } from '@element-softworks/daintree';

const Header = ({
    title,
    description,
    graphic,
    layout,
    graphicName,
    backgroundTransparent,
    disablePadding,
}) => {

    return (
        <Fragment>
            {!!graphic && (
                <div className={styles.header}>
                    <Grid>
                        <div className={styles.defaultHero}>
                            <Row>
                                <Col xl={7} lg={7} md={12} sm={12} xs={12}>
                                    <h1>{title}</h1>
                                    <Divider />
                                    <div
                                        className={`${styles.description} ${
                                            layout === 'about' ? styles.aboutPageDescription : ''
                                        }`}
                                        dangerouslySetInnerHTML={{
                                            __html: description,
                                        }}
                                    />
                                </Col>
                                <Col xl={5} lg={5} md={12} sm={12} xs={12}>
                                    <div className={styles.heroDefaultGraphicWrapper}>
                                        <div className={styles.heroGraphicContainer}>
                                            {
                                                (layout = 'privacy' ? (
                                                    <div className={styles.privacyGraphics}>
                                                        <img src={graphicName} alt={graphicName} />
                                                    </div>
                                                ) : (
                                                    <img src={graphicName} alt={graphicName} />
                                                ))
                                            }
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Grid>
                </div>
            )}

            {layout === 'about' && (
                <Fragment>
                    <div className={`${styles.headerWithGraphics} ${backgroundTransparent ? styles.transparent : ''}`}>
                        <Grid>
                            <h1>{title}</h1>
                            <Divider />
                            <div
                                className={`${styles.description} ${
                                    layout === 'about' ? styles.aboutPageDescription : ''
                                }`}
                                dangerouslySetInnerHTML={{
                                    __html: description,
                                }}
                            />
                        </Grid>
                    </div>
                    <div className={styles.graphicAboutContainer}>
                        <img src={"/images/onwards.svg"} alt="onwards graphic" />
                    </div>
                </Fragment>
            )}
            {layout === 'case-study' && (
                <Fragment>
                    <div className={styles.headerWithGraphics}>
                        <Grid>
                            <h1 style={{position: 'relative'}}>{title}</h1>
                            <Divider />
                            <div
                                className={`${styles.description} ${
                                    layout === 'about' ? styles.aboutPageDescription : ''
                                }`}
                                dangerouslySetInnerHTML={{
                                    __html: description,
                                }}
                            />
                        </Grid>
                    </div>
                    <div className={styles.graphicCaseStudyContainer}>
                        <img src={graphicName} alt="basketball field graphic" />
                    </div>
                </Fragment>
            )}
            {layout === 'become-a-pa' && (
                <Fragment>
                    <div className={`${styles.headerWithGraphics} ${backgroundTransparent ? styles.transparent : ''} ${disablePadding ? styles.disablePadding : ''}`}>
                       <div>
                            <h1 style={{position: 'relative'}}>{title}</h1>
                            <Divider />
                            <div
                                className={`${styles.description} ${
                                    layout === 'about' ? styles.aboutPageDescription : ''
                                }`}
                                dangerouslySetInnerHTML={{
                                    __html: description,
                                }}
                            />
                       </div>
                        <div className={`${styles.graphicPaContainer} ${backgroundTransparent ? styles.transparent : ''}`}>
                            <img src={graphic?.publicURL ?? graphicName} alt="basketball field graphic" className={styles.paImage}/>
                        </div>
                    </div>
                </Fragment>
            )}
        </Fragment>
    );
};

export default Header;
